import type * as CSS from "csstype";
import Link from "next/link";
import { ReactNode, useState } from "react";
import { Color } from "../theme/theme";

export default function StyledLink({
  children,
  style,
  href,
  color,
}: {
  children: ReactNode;
  style?: CSS.Properties;
  href: string;
  color?: string;
}): JSX.Element {
  const [hover, setHover] = useState(false);
  return (
    <Link href={href}>
      <span
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          color: hover ? Color.grey : Color.green,
          cursor: "pointer",
          textDecoration: "underline",
          ...style,
        }}
      >
        <span style={{ color: color || Color.dark }}>{children}</span>
      </span>
    </Link>
  );
}
