import { gql } from "@apollo/client";
import { signIn } from "next-auth/react";
import Link from "next/link";
import { useContext } from "react";
import Button from "../components/Button";
import FollowingStories from "../components/FollowingStories";
import MetaHeader from "../components/MetaHeader";
import PageTemplate from "../components/PageTemplate";
import PeopleToFollow from "../components/PeopleToFollow";
import StyledLink from "../components/StyledLink";
import { MeContext } from "../hooks/context";
import { useIsMobile } from "../hooks/isMobile.hook";
import { Color } from "../theme/theme";

const QUERY = gql`
  query index {
    me {
      _id
      username
      name
      following {
        _id
        name
        image
        username
        articleOfTheWeek {
          title
          published
          shortText
          slug
          isDeleted
        }
      }
      peopleToFollow {
        _id
        name
        image
        username
        articleOfTheWeek {
          title
          published
          shortText
          slug
          isDeleted
        }
      }
      articleOfTheWeek {
        title
        published
        shortText
        slug
        isDeleted
      }
    }
  }
`;

export default function Home() {
  const { me, setMe } = useContext(MeContext);
  const isMobile = useIsMobile();

  function updateFollowing(_id: string) {
    setMe({
      variables: {
        input: {
          following: [...me.following.map((x) => x._id), _id],
        },
      },
    });
  }

  return (
    <>
      <MetaHeader
        title={`DecodedBlog`}
        description={"Create and read trustworthy information for free"}
      />

      <PageTemplate style={{ padding: "0" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: me ? "calc(100% - 96px)" : "100%",
            backgroundColor: Color.sand,
            color: Color.dark,
            padding: "24px",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
            <h1
              style={{
                lineHeight: "1.148",
                fontSize: "24px",
              }}
            >
              {!me && (
                <div
                  style={{
                    gap: "12px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ paddingBottom: "36px" }}>
                    Welcome to DecodedBlog{" "}
                  </div>
                  <Button
                    style={{ fontSize: "24px" }}
                    onClick={() => signIn("auth0")}
                  >
                    Sign in
                  </Button>
                  <div style={{ color: Color.wood }}>~or~</div>
                  <Link href="/about">
                    <Button style={{ fontSize: "24px" }}>Read About Us</Button>
                  </Link>
                </div>
              )}
              {me && (
                <>
                  Welcome Back,{" "}
                  <StyledLink href={`/user/${me.username}`}>
                    {me.name}
                  </StyledLink>
                  <br />
                  {!me.articleOfTheWeek && (
                    <div
                      style={{
                        paddingTop: "48px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Link href="/create">
                        <Button>create your content for the week</Button>
                      </Link>
                    </div>
                  )}
                </>
              )}
            </h1>
          </div>
          <img
            src="/skullbook.png"
            alt="text logo"
            style={{ width: "100%", maxWidth: "1200px" }}
          />
        </div>
        {me?.following.length || me?.articleOfTheWeek ? (
          <div style={{ padding: "48px" }}>
            <h1>This week's dig</h1>
            <FollowingStories
              me={me}
              following={me.following}
            ></FollowingStories>
          </div>
        ) : null}
        {me?.peopleToFollow.length ? (
          <div
            style={{
              padding: "48px",
              backgroundColor: Color.green,
              alignItems: "flex-end",
              display: "flex",
              flexDirection: isMobile ? "column" : null,
            }}
          >
            <h1 style={{ color: Color.white }}>People to Follow:</h1>
            <PeopleToFollow
              peopleToFollow={me.peopleToFollow}
              following={me.following}
              updateFollowing={updateFollowing}
            ></PeopleToFollow>
          </div>
        ) : null}
      </PageTemplate>
    </>
  );
}
