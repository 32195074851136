import { useState } from "react";
import { Color } from "../theme/theme";
import { User } from "../types/graphql";
import GenericButton from "./Button";
import StyledLink from "./StyledLink";

function Card({
  name,
  image,
  username,
  articleOfTheWeek,
  _id,
  following,
  updateFollowing,
}: User & { updateFollowing: (_id: string) => void }) {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        backgroundColor: Color.white,
        padding: "24px",
        borderRadius: "4px",
        boxShadow: "10px 10px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <img
          style={{
            borderRadius: "50%",
            height: "48px",
            width: "48px",
            objectFit: "cover",
          }}
          src={image}
        />
        <StyledLink href={`/user/${username}`}>
          <span
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {name}
          </span>
        </StyledLink>
        <GenericButton
          onClick={() => {
            updateFollowing(_id);
          }}
        >
          Follow
        </GenericButton>
      </div>
    </div>
  );
}

export default function PeopleToFollow({
  peopleToFollow,
  following,
  updateFollowing,
}: {
  peopleToFollow: User["peopleToFollow"];
  following: User["following"];
  updateFollowing: (_id: string) => void;
}) {
  return (
    <div
      style={{
        display: "flex",
        gap: "24px",
        width: "100%",
        overflowX: "auto",
        padding: "24px",
      }}
    >
      {peopleToFollow?.map((o, key) => (
        <Card
          {...o}
          following={following}
          key={key}
          updateFollowing={updateFollowing}
        />
      ))}
    </div>
  );
}
