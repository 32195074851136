import { useIsMobile } from "../hooks/isMobile.hook";
import { User } from "../types/graphql";
import { NoStoryCard, StoryCard } from "./StoryCard";

export default function FollowingStories({
  following,
  me,
}: {
  me: User;
  following: User["following"];
}) {
  const isMobile = useIsMobile();
  const followingSorted = [...following].sort((a, b) => {
    return a.articleOfTheWeek ? -1 : 1;
  });
  return (
    <div
      style={
        isMobile
          ? {
              display: "flex",
              gap: "24px",
              flexDirection: "column",
            }
          : {
              paddingTop: "12px",
              display: "flex",
              gap: "24px",
              width: "100%",
              overflowX: "auto",
              padding: "24px",
            }
      }
    >
      {" "}
      <StoryCard {...me} isMe={true}></StoryCard>
      {followingSorted.map((o, key) =>
        o.articleOfTheWeek && !o.articleOfTheWeek.isDeleted ? (
          <StoryCard {...o} key={key} />
        ) : (
          <NoStoryCard {...o} key={key} />
        )
      )}
    </div>
  );
}
